<script>
export default {
  components: {
    CardTable: () => import("@/components/cardTable/CardTableV2.vue"),
    liquidaciones: () => import("@/views/Helexium/Nomina/Liquidacion/Liquidacion"),
    BtnAddCardTable: () => import("@/components/btnCardTable/btnAddCardTable.vue"),
  },
  data() {
    return {
      ultimaNomina: undefined,
      cardData: [
        {
          title: "Salarios",
          value: () => this.formatoMoneda(this.ultimaNomina.totalSalarios),
          iconName: "money-bill-1",
          color: "rgba(71, 136, 255, 1)",
          tipo: "shield",
          animated: "bounce",
        },
        {
          title: "Pagos adicionales",
          value: () => this.formatoMoneda(this.ultimaNomina.totalPagosAdicionales),
          iconName: "hand-point-up",
          color: "rgba(71,136,255,1)",
          tipo: "shield",
          animated: "bounce",
        },
        {
          title: "Deducciones",
          value: () => this.formatoMoneda(this.ultimaNomina.totalDeducciones),
          iconName: "hand-point-down",
          color: "rgba(223, 18, 46, 1)",
          tipo: "shield",
          animated: "bounce",
        },
        {
          title: "Total a pagar",
          value: () => this.formatoMoneda(this.ultimaNomina.totalAPagar),
          iconName: "hand-holding-dollar",
          color: "rgba(71, 136, 255, 1)",
          tipo: "shield",
          animated: "bounce",
        },
      ],
    };
  },
  async mounted() {
    const response = await this.$store.getters.fetchGet({ path: `NominaLiquidacion/GetUltimaLiquidacionEnviada` });
    this.ultimaNomina = await response.json();
  },
  methods: {
    formatoMoneda(valor) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(valor);
    },
  },
};
</script>

<template>
  <div>
    <b-card class="mx-3 px-2">
      <h3 class="ml-0 mb-2">Última nómina enviada</h3>
      <div class="row" style="gap: 1rem">
        <div class="col-12 col-md-10">
          <CardTable :data="cardData" />
        </div>
        <div class="col-12 col-md">
          <BtnAddCardTable @click="$router.push({ name: 'NominaProceso' })" variant="success" text="Nueva nomina" />
        </div>
      </div>
    </b-card>

    <liquidaciones />
  </div>
</template>
